import { render, staticRenderFns } from "./purchase-specification-modal.vue?vue&type=template&id=77978e4a&scoped=true&"
import script from "./purchase-specification-modal.vue?vue&type=script&lang=js&"
export * from "./purchase-specification-modal.vue?vue&type=script&lang=js&"
import style0 from "./purchase-specification-modal.vue?vue&type=style&index=0&id=77978e4a&lang=css&"
import style1 from "./purchase-specification-modal.vue?vue&type=style&index=1&id=77978e4a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77978e4a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/stage-ext-new/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77978e4a')) {
      api.createRecord('77978e4a', component.options)
    } else {
      api.reload('77978e4a', component.options)
    }
    module.hot.accept("./purchase-specification-modal.vue?vue&type=template&id=77978e4a&scoped=true&", function () {
      api.rerender('77978e4a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/common/purchase-specification-modal.vue"
export default component.exports